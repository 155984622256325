import * as React from "react"
import * as css from "./not-found-page.module.scss"
import { Helmet } from "react-helmet"
import { useTranslation } from "../../../common/use-translations/use-translations"

export function NotFoundPage() {
  const _ = useTranslation();
  return (
    <>
      <Helmet>
        <title>{_.page_not_found}</title>
      </Helmet>
      <div className="container">
        <h1 className={css.error}>404</h1>
      </div>
    </>
  )
}
