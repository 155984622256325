import * as React from "react"
import "./../style/common.scss"
import { CommonLayout } from "../common/common-layout/common-layout"
import { NotFoundPage } from "../page-components/404/404-page/not-found-page"

export default function NotFound(props) {
  return (
    <CommonLayout {...props}>
      <NotFoundPage />
    </CommonLayout>
  )
}
